import { mapGetters, mapState } from 'vuex';
import { get } from 'lodash-es';
import moment from 'moment';
import SET_PROFILE_KEY from '@/graphql/SetProfileKey.gql';
import GET_HAD_IMPRESSION_EVER from '@/graphql/GetHadImpressionEver.gql';
import CHECK_SHOPIFY_APP_EMBED_STATUSES from '@/graphql/CheckShopifyAppEmbedStatuses.gql';

export default {
  data: () => ({
    hadImpressionEver: false,
    shopifyAppEmbedStatuses: null,
  }),
  computed: {
    ...mapState(['account']),
    ...mapGetters(['domains', 'shopSettingsByDomain']),

    hasConversion() {
      const firstConversion = get(this.account, 'profile.firstConversion', false);
      const firstFiveConversion = get(this.account, 'profile.firstFiveConversion', false);
      return !!firstConversion || !!firstFiveConversion;
    },

    firstDomainShopSetting() {
      const firstDomain = this.domains[0]?.domain;
      return this.shopSettingsByDomain(firstDomain);
    },

    hasDomainRequested() {
      return this.domains.some(({ v3LastRequestDate = null }) => {
        return v3LastRequestDate;
      });
    },

    isFullyConnected() {
      if (this.needCheckShopifyAppEmbed) {
        const isV3Requested = !!this.domains[0]?.v3LastRequestDate;
        const isShopifyAppEmbedEnabled = this.shopifyAppEmbedStatuses?.[0];
        return isV3Requested && isShopifyAppEmbedEnabled;
      }

      return this.hasDomainRequested;
    },

    needCheckShopifyAppEmbed() {
      return (
        this.domains.length === 1 &&
        this.firstDomainShopSetting &&
        this.firstDomainShopSetting?.type === 'shopify' &&
        this.firstDomainShopSetting?.isShopifyAppExtensionActive
      );
    },

    isCreatedLastWeek() {
      const rollingLastWeekStart = moment().subtract(1, 'weeks').startOf('day');
      return moment(this.account.createdAt).isAfter(rollingLastWeekStart);
    },
  },
  methods: {
    async setToUserProfile(key, value) {
      return this.$apollo.mutate({
        mutation: SET_PROFILE_KEY,
        variables: { key, value },
      });
    },
    async getHadImpressionEver() {
      if (!this.campaignCount || this.isCreatedLastWeek) return false;
      try {
        const {
          data: { result: hadImpressionEver },
        } = await this.$apollo.query({
          query: GET_HAD_IMPRESSION_EVER,
        });
        return hadImpressionEver;
      } catch (e) {
        console.log('error', e);
      }
      return false;
    },

    async setImpressionFlag() {
      if (!this.isActivatedBefore && !this.hasConversion) {
        this.hadImpressionEver = await this.getHadImpressionEver();
      }
    },

    async setShopifyAppEmbedStatuses() {
      if (!this.needCheckShopifyAppEmbed) {
        this.shopifyAppEmbedStatuses = [true];
        return;
      }
      const status = await this.verifyAppEmbedStatuses([this.firstDomainShopSetting.live_domain]);
      this.shopifyAppEmbedStatuses = status.map(({ disabled }) => !disabled);
    },

    async verifyAppEmbedStatuses(myshopifyDomains) {
      const {
        data: { appEmbedStatuses },
      } = await this.$apollo.mutate({
        mutation: CHECK_SHOPIFY_APP_EMBED_STATUSES,
        variables: {
          myshopifyDomains,
        },
      });
      return appEmbedStatuses;
    },
  },
};
