import { track } from '@/services/xray';
import SAVE_VIP_ONBOARDING_REQUEST from '@/graphql/SaveVIPOnboardingRequest.gql';

export default {
  data() {
    return {
      vipRequested: false,
    };
  },
  methods: {
    async requestVIP() {
      track(
        this.$route.path.includes('onboarding')
          ? 'request-VIPCall-onboarding'
          : 'request-VIPCall-dashboard',
      );
      const {
        data: { saveVIPOnboardingRequest },
      } = await this.$apollo.mutate({
        mutation: SAVE_VIP_ONBOARDING_REQUEST,
      });
      if (saveVIPOnboardingRequest) {
        this.vipRequested = true;
      }
    },
  },
};
